const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-central-1_Tnz5SDICG',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '28nb7k58d92hqun0jnmkrp4eqe',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    /*
     * OPTIONAL - Configuration for cookie storage
     * Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
     */
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: 'staging.carswip.com',
      /*
       *  OPTIONAL - Cookie path
       * path: '/',
       *  OPTIONAL - Cookie expiration in days
       */
      expires: 1,
      /*
       * OPTIONAL - Cookie secure flag
       * Either true or false, indicating if the cookie transmission requires a secure protocol (https).
       */
      secure: true,
      // CSRF
      sameSite: 'lax',
    },
  },
  ApiUrl: {
    Rest: 'https://backend-api.staging.carswip.com/auth',
    PublicRest: 'https://backend-api.staging.carswip.com',
    WebSocket: 'https://backend-api.staging.carswip.com',
  },
  Branding: {
    LoginBackground: {
      'staging.carswip.com': '/media/bg/carswip-bg.jpg',
    },
  },
};

export default config;
